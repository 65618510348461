/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyListInput } from '../models/CompanyListInput';
import type { CompanyListOutput } from '../models/CompanyListOutput';
import type { CompanyListSummaryOutput } from '../models/CompanyListSummaryOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CompanyListService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Company List
     * @param organizationId
     * @param requestBody
     * @returns CompanyListOutput Successful Response
     * @throws ApiError
     */
    public createCompanyList(
        organizationId: string,
        requestBody: CompanyListInput,
    ): CancelablePromise<CompanyListOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/company-list/',
            query: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Company Lists
     * @param organizationId
     * @returns CompanyListSummaryOutput Successful Response
     * @throws ApiError
     */
    public listCompanyLists(
        organizationId: string,
    ): CancelablePromise<Array<CompanyListSummaryOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-list/',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Company List
     * @param companyListId
     * @param organizationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteCompanyList(
        companyListId: string,
        organizationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/company-list/{company_list_id}',
            path: {
                'company_list_id': companyListId,
            },
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
