/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CognismJobFunction {
    ACCOUNTING = 'Accounting',
    BUSINESS = 'Business',
    CLIENT_SUCCESS = 'Client success',
    HR = 'HR',
    LEADERSHIP = 'Leadership',
    LEGAL = 'Legal',
    MARKETING = 'Marketing',
    OPERATIONS = 'Operations',
    PRODUCTION = 'Production',
    SALES = 'Sales',
    TECHNOLOGY = 'Technology',
}
