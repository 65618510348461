/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { ActivityFeedService } from './services/ActivityFeedService';
import { AgentsService } from './services/AgentsService';
import { BlocklistService } from './services/BlocklistService';
import { CalendarsService } from './services/CalendarsService';
import { CampaignsService } from './services/CampaignsService';
import { CompaniesService } from './services/CompaniesService';
import { CompanyListService } from './services/CompanyListService';
import { ConstantsService } from './services/ConstantsService';
import { ContactsService } from './services/ContactsService';
import { CrmService } from './services/CrmService';
import { CrmOauthService } from './services/CrmOauthService';
import { EmailsService } from './services/EmailsService';
import { EmailValidatorService } from './services/EmailValidatorService';
import { FeedbackService } from './services/FeedbackService';
import { HealthService } from './services/HealthService';
import { InvitesService } from './services/InvitesService';
import { LeadFinderService } from './services/LeadFinderService';
import { LeadImportService } from './services/LeadImportService';
import { LeadSearchService } from './services/LeadSearchService';
import { MembershipsService } from './services/MembershipsService';
import { MessageTemplatesService } from './services/MessageTemplatesService';
import { MetricsService } from './services/MetricsService';
import { NylasService } from './services/NylasService';
import { OrganizationsService } from './services/OrganizationsService';
import { PersonaService } from './services/PersonaService';
import { ScraperService } from './services/ScraperService';
import { SequencesService } from './services/SequencesService';
import { StripeService } from './services/StripeService';
import { SubscriptionsService } from './services/SubscriptionsService';
import { SuggestionsService } from './services/SuggestionsService';
import { TestService } from './services/TestService';
import { UsersService } from './services/UsersService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class Client {
    public readonly activityFeed: ActivityFeedService;
    public readonly agents: AgentsService;
    public readonly blocklist: BlocklistService;
    public readonly calendars: CalendarsService;
    public readonly campaigns: CampaignsService;
    public readonly companies: CompaniesService;
    public readonly companyList: CompanyListService;
    public readonly constants: ConstantsService;
    public readonly contacts: ContactsService;
    public readonly crm: CrmService;
    public readonly crmOauth: CrmOauthService;
    public readonly emails: EmailsService;
    public readonly emailValidator: EmailValidatorService;
    public readonly feedback: FeedbackService;
    public readonly health: HealthService;
    public readonly invites: InvitesService;
    public readonly leadFinder: LeadFinderService;
    public readonly leadImport: LeadImportService;
    public readonly leadSearch: LeadSearchService;
    public readonly memberships: MembershipsService;
    public readonly messageTemplates: MessageTemplatesService;
    public readonly metrics: MetricsService;
    public readonly nylas: NylasService;
    public readonly organizations: OrganizationsService;
    public readonly persona: PersonaService;
    public readonly scraper: ScraperService;
    public readonly sequences: SequencesService;
    public readonly stripe: StripeService;
    public readonly subscriptions: SubscriptionsService;
    public readonly suggestions: SuggestionsService;
    public readonly test: TestService;
    public readonly users: UsersService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8000',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.activityFeed = new ActivityFeedService(this.request);
        this.agents = new AgentsService(this.request);
        this.blocklist = new BlocklistService(this.request);
        this.calendars = new CalendarsService(this.request);
        this.campaigns = new CampaignsService(this.request);
        this.companies = new CompaniesService(this.request);
        this.companyList = new CompanyListService(this.request);
        this.constants = new ConstantsService(this.request);
        this.contacts = new ContactsService(this.request);
        this.crm = new CrmService(this.request);
        this.crmOauth = new CrmOauthService(this.request);
        this.emails = new EmailsService(this.request);
        this.emailValidator = new EmailValidatorService(this.request);
        this.feedback = new FeedbackService(this.request);
        this.health = new HealthService(this.request);
        this.invites = new InvitesService(this.request);
        this.leadFinder = new LeadFinderService(this.request);
        this.leadImport = new LeadImportService(this.request);
        this.leadSearch = new LeadSearchService(this.request);
        this.memberships = new MembershipsService(this.request);
        this.messageTemplates = new MessageTemplatesService(this.request);
        this.metrics = new MetricsService(this.request);
        this.nylas = new NylasService(this.request);
        this.organizations = new OrganizationsService(this.request);
        this.persona = new PersonaService(this.request);
        this.scraper = new ScraperService(this.request);
        this.sequences = new SequencesService(this.request);
        this.stripe = new StripeService(this.request);
        this.subscriptions = new SubscriptionsService(this.request);
        this.suggestions = new SuggestionsService(this.request);
        this.test = new TestService(this.request);
        this.users = new UsersService(this.request);
    }
}

