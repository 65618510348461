/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PersonaInput } from '../models/PersonaInput';
import type { PersonaOutput } from '../models/PersonaOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PersonaService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Persona
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPersona(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/persona/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Organization Persona
     * @param organizationId
     * @returns PersonaOutput Successful Response
     * @throws ApiError
     */
    public getOrganizationPersona(
        organizationId: string,
    ): CancelablePromise<PersonaOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/persona/organization/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upsert Organization Persona
     * @param organizationId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public upsertOrganizationPersona(
        organizationId: string,
        requestBody: PersonaInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/persona/organization/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Campaign Persona
     * @param organizationId
     * @param campaignId
     * @returns PersonaOutput Successful Response
     * @throws ApiError
     */
    public getCampaignPersona(
        organizationId: string,
        campaignId: string,
    ): CancelablePromise<PersonaOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/persona/organization/{organization_id}/campaign/{campaign_id}',
            path: {
                'organization_id': organizationId,
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upsert Campaign Persona
     * @param organizationId
     * @param campaignId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public upsertCampaignPersona(
        organizationId: string,
        campaignId: string,
        requestBody: PersonaInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/persona/organization/{organization_id}/campaign/{campaign_id}',
            path: {
                'organization_id': organizationId,
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
