/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CRMType } from '../models/CRMType';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CrmOauthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Auth Url
     * @param crmType
     * @param organizationId
     * @returns string Successful Response
     * @throws ApiError
     */
    public getAuthUrl(
        crmType: CRMType,
        organizationId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/crm/oauth/url/{crm_type}',
            path: {
                'crm_type': crmType,
            },
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Auth Callback
     * @param code
     * @param state
     * @returns string Successful Response
     * @throws ApiError
     */
    public authCallback(
        code: string,
        state: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/crm/oauth/callback',
            query: {
                'code': code,
                'state': state,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
