/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentOutput } from '../models/AgentOutput';
import type { AgentUpdateCampaignAssociationInput } from '../models/AgentUpdateCampaignAssociationInput';
import type { AgentUpdateInput } from '../models/AgentUpdateInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AgentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Agents From Organization
     * @param organizationId
     * @returns AgentOutput Successful Response
     * @throws ApiError
     */
    public getAgentsFromOrganization(
        organizationId: string,
    ): CancelablePromise<Array<AgentOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/agents/',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Agent
     * @param id
     * @returns AgentOutput Successful Response
     * @throws ApiError
     */
    public getAgent(
        id: string,
    ): CancelablePromise<AgentOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/agents/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Agent
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteAgent(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/agents/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Agent
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateAgent(
        id: string,
        requestBody: AgentUpdateInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/agents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Campaign For Agent
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateCampaignForAgent(
        id: string,
        requestBody: AgentUpdateCampaignAssociationInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/agents/{id}/campaign-association',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
