/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BlocklistType {
    ORGANIZATION_TO_CONTACT = 'organization_to_contact',
    ANY_ORGANIZATION_TO_CONTACT = 'any_organization_to_contact',
    ORGANIZATION_TO_COMPANY = 'organization_to_company',
    ANY_ORGANIZATION_TO_COMPANY = 'any_organization_to_company',
}
