/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MessageReplyCategory {
    BAD_TIMING = 'bad_timing',
    BOUNCED = 'bounced',
    INTERESTED = 'interested',
    MEETING = 'meeting',
    OTHER_NEGATIVE = 'other_negative',
    OTHER_NEUTRAL = 'other_neutral',
    OTHER_POSITIVE = 'other_positive',
    OUT_OF_OFFICE = 'out_of_office',
    SPAM_FILTER = 'spam_filter',
    UNKNOWN = 'unknown',
    UNSUBSCRIBED = 'unsubscribed',
    WRONG_PERSON = 'wrong_person',
}
