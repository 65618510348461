/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganizationInput } from '../models/CreateOrganizationInput';
import type { ExtractOrganizationSummaryInput } from '../models/ExtractOrganizationSummaryInput';
import type { ExtractOrganizationSummaryOutput } from '../models/ExtractOrganizationSummaryOutput';
import type { OrganizationOutput } from '../models/OrganizationOutput';
import type { Page_OrganizationOutput_ } from '../models/Page_OrganizationOutput_';
import type { UpdateOrganizationInput } from '../models/UpdateOrganizationInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrganizationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * All Organizations
     * @param search
     * @param page Page number
     * @param size Page size
     * @returns Page_OrganizationOutput_ Successful Response
     * @throws ApiError
     */
    public allOrganizations(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_OrganizationOutput_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organizations/',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Organization
     * @param requestBody
     * @returns OrganizationOutput Successful Response
     * @throws ApiError
     */
    public createOrganization(
        requestBody: CreateOrganizationInput,
    ): CancelablePromise<OrganizationOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/organizations/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Active Organizations
     * @returns OrganizationOutput Successful Response
     * @throws ApiError
     */
    public activeOrganizations(): CancelablePromise<Array<OrganizationOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organizations/active',
        });
    }
    /**
     * Update Search Context
     * @param id
     * @param searchContext
     * @returns OrganizationOutput Successful Response
     * @throws ApiError
     */
    public updateSearchContext(
        id: string,
        searchContext: string,
    ): CancelablePromise<OrganizationOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/organizations/{id}/search-context',
            path: {
                'id': id,
            },
            query: {
                'search_context': searchContext,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Find Organization
     * @param id
     * @returns OrganizationOutput Successful Response
     * @throws ApiError
     */
    public findOrganization(
        id: string,
    ): CancelablePromise<OrganizationOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organizations/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Organization
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateOrganization(
        id: string,
        requestBody: UpdateOrganizationInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Extract Organization Summary
     * @param requestBody
     * @returns ExtractOrganizationSummaryOutput Successful Response
     * @throws ApiError
     */
    public extractOrganizationSummary(
        requestBody: ExtractOrganizationSummaryInput,
    ): CancelablePromise<ExtractOrganizationSummaryOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/organizations/extract-summary',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
