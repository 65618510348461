/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CognismSeniorityUnofficial {
    ENTRY_LEVEL = 'Entry-Level',
    EXPERIENCED_STAFF = 'Experienced Staff',
    TEAM_LEAD = 'Team-Lead',
    MIDDLE_MANAGEMENT = 'Middle-Management',
    SENIOR_LEADERSHIP = 'Senior Leadership',
    CX_O = 'CxO',
    EXECUTIVE_LEVEL = 'Executive-Level',
}
