/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ActivityType {
    MESSAGE_SENT = 'message_sent',
    MESSAGE_REPLIED = 'message_replied',
    MESSAGE_OPENED = 'message_opened',
    MESSAGE_LINK_CLICKED = 'message_link_clicked',
    HOT_LEAD = 'hot_lead',
    MEETING = 'meeting',
}
