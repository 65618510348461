/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartDataOutput } from '../models/ChartDataOutput';
import type { LatestHotLeadsOutput } from '../models/LatestHotLeadsOutput';
import type { LatestRepliesOutput } from '../models/LatestRepliesOutput';
import type { MetricOutput } from '../models/MetricOutput';
import type { MetricsFilters } from '../models/MetricsFilters';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MetricsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Metrics Per Organization
     * @param organizationId
     * @param requestBody
     * @returns MetricOutput Successful Response
     * @throws ApiError
     */
    public getMetricsPerOrganization(
        organizationId: string,
        requestBody: MetricsFilters,
    ): CancelablePromise<MetricOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/metrics/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Messages Sent
     * @param organizationId
     * @param requestBody
     * @returns ChartDataOutput Successful Response
     * @throws ApiError
     */
    public getMessagesSent(
        organizationId: string,
        requestBody: MetricsFilters,
    ): CancelablePromise<Array<ChartDataOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/metrics/{organization_id}/messages-sent',
            path: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Latest Replies
     * @param organizationId
     * @param requestBody
     * @param limit
     * @returns LatestRepliesOutput Successful Response
     * @throws ApiError
     */
    public getLatestReplies(
        organizationId: string,
        requestBody: MetricsFilters,
        limit: number = 5,
    ): CancelablePromise<Array<LatestRepliesOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/metrics/{organization_id}/latest-replies',
            path: {
                'organization_id': organizationId,
            },
            query: {
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Latest Hot Leads
     * @param organizationId
     * @param requestBody
     * @param limit
     * @returns LatestHotLeadsOutput Successful Response
     * @throws ApiError
     */
    public getLatestHotLeads(
        organizationId: string,
        requestBody: MetricsFilters,
        limit: number = 5,
    ): CancelablePromise<Array<LatestHotLeadsOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/metrics/{organization_id}/latest-hot-leads',
            path: {
                'organization_id': organizationId,
            },
            query: {
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Leads Contacted
     * @param organizationId
     * @param requestBody
     * @returns ChartDataOutput Successful Response
     * @throws ApiError
     */
    public getLeadsContacted(
        organizationId: string,
        requestBody: MetricsFilters,
    ): CancelablePromise<Array<ChartDataOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/metrics/{organization_id}/leads-contacted',
            path: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Positive Replies
     * @param organizationId
     * @param requestBody
     * @returns ChartDataOutput Successful Response
     * @throws ApiError
     */
    public getPositiveReplies(
        organizationId: string,
        requestBody: MetricsFilters,
    ): CancelablePromise<Array<ChartDataOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/metrics/{organization_id}/positive-replies',
            path: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
