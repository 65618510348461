/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TestService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Alerts
     * @param grantId
     * @returns any Successful Response
     * @throws ApiError
     */
    public alerts(
        grantId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/test/alert/{grant_id}',
            path: {
                'grant_id': grantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
