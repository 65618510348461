/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CognismSkill {
    ALGORITHMS = 'Algorithms',
    ANALYTICS = 'Analytics',
    ANDROID = 'Android',
    APPLICATIONS = 'Applications',
    BLOGGING = 'Blogging',
    BUSINESS = 'Business',
    BUSINESS_ANALYSIS = 'Business Analysis',
    BUSINESS_INTELLIGENCE = 'Business Intelligence',
    BUSINESS_STORYTELLING = 'Business Storytelling',
    COACHING = 'Coaching',
    CLOUD_COMPUTING = 'Cloud Computing',
    COMMUNICATION = 'Communication',
    COMPUTER = 'Computer',
    CONSULTING = 'Consulting',
    CONTENT = 'Content',
    CONTENT_MANAGEMENT = 'Content Management',
    CONTENT_MARKETING = 'Content Marketing',
    CONTENT_STRATEGY = 'Content Strategy',
    DATA_ANALYSIS = 'Data Analysis',
    DATA_ANALYTICS = 'Data Analytics',
    DATA_ENGINEERING = 'Data Engineering',
    DATA_MINING = 'Data Mining',
    DATA_SCIENCE = 'Data Science',
    DATA_WAREHOUSING = 'Data Warehousing',
    DATABASE_ADMINISTRATION = 'Database Administration',
    DATABASE_MANAGEMENT = 'Database Management',
    DIGITAL_MARKETING = 'Digital Marketing',
    DIGITAL_MEDIA = 'Digital Media',
    ECONOMICS = 'Economics',
    EDITING = 'Editing',
    EXECUTIVE = 'Executive',
    EVENT_PLANNING = 'Event Planning',
    GAME_DEVELOPMENT = 'Game Development',
    HADOOP = 'Hadoop',
    HEALTH_CARE = 'Health Care',
    HIRING = 'Hiring',
    HOSPITALITY = 'Hospitality',
    HUMAN_RESOURCES = 'Human Resources',
    INFORMATION_MANAGEMENT = 'Information Management',
    INFORMATION_SECURITY = 'Information Security',
    INFORMATION_TECHNOLOGY = 'Information Technology',
    I_PHONE = 'iPhone',
    JAVA = 'Java',
    JOB_SPECIFIC_SKILLS = 'Job Specific Skills',
    LEGAL = 'Legal',
    LEADERSHIP = 'Leadership',
    MAC = 'Mac',
    MANAGEMENT = 'Management',
    MARKETING = 'Marketing',
    MARKET_RESEARCH = 'Market Research',
    MEDIA_PLANNING = 'Media Planning',
    MICROSOFT_OFFICE_SKILLS = 'Microsoft Office Skills',
    MOBILE_APPS = 'Mobile Apps',
    MOBILE_DEVELOPMENT = 'Mobile Development',
    NEGOTIATION = 'Negotiation',
    NETWORK_AND_INFORMATION_SECURITY = 'Network and Information Security',
    NEWSLETTERS = 'Newsletters',
    ONLINE_MARKETING = 'Online Marketing',
    PRESENTATION = 'Presentation',
    PROJECT_MANAGEMENT = 'Project Management',
    PUBLIC_RELATIONS = 'Public  Relations',
    RECRUITING = 'Recruiting',
    RELATIONSHIP_MANAGEMENT = 'Relationship Management',
    RESEARCH = 'Research',
    RISK_MANAGEMENT = 'Risk Management',
    SEARCH_ENGINE_OPTIMIZATION_SEO_ = 'Search Engine Optimization (SEO)',
    SOCIAL_MEDIA = 'Social Media',
    SOCIAL_MEDIA_MANAGEMENT = 'Social Media Management',
    SOCIAL_NETWORKING = 'Social Networking',
    SOFTWARE = 'Software',
    SOFTWARE_ENGINEERING = 'Software Engineering',
    SOFTWARE_MANAGEMENT = 'Software Management',
    STRATEGIC_PLANNING = 'Strategic Planning',
    STRATEGY = 'Strategy',
    TECH_SKILLS_LISTED_BY_JOB = 'Tech Skills Listed by Job',
    TECH_SUPPORT = 'Tech Support',
    TECHNICAL = 'Technical',
    TRAINING = 'Training',
    UI_UX = 'UI / UX',
    USER_TESTING = 'User Testing',
    WEB_CONTENT = 'Web Content',
    WEB_DEVELOPMENT = 'Web Development',
    WEB_PROGRAMMING = 'Web Programming',
    WORD_PRESS = 'WordPress',
    WRITING = 'Writing',
}
