/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthorizeRequest } from '../models/AuthorizeRequest';
import type { AuthorizeResponse } from '../models/AuthorizeResponse';
import type { WebhookResultStatus } from '../models/WebhookResultStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NylasService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Webhook
     * @returns WebhookResultStatus Successful Response
     * @throws ApiError
     */
    public webhook(): CancelablePromise<WebhookResultStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/nylas/webhook',
        });
    }
    /**
     * Authorize
     * @param requestBody
     * @returns AuthorizeResponse Successful Response
     * @throws ApiError
     */
    public authorize(
        requestBody: AuthorizeRequest,
    ): CancelablePromise<AuthorizeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/nylas/oauth/authorize',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Nylas Auth Callback
     * @param code
     * @param state
     * @returns string Successful Response
     * @throws ApiError
     */
    public nylasAuthCallback(
        code: string,
        state: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/nylas/oauth/callback',
            query: {
                'code': code,
                'state': state,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
