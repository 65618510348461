/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CognismWorldRegion {
    NORTH_AMERICA = 'NORTH_AMERICA',
    MIDDLE_EAST = 'MIDDLE_EAST',
    EMEA = 'EMEA',
    EUROPE = 'EUROPE',
    DACH = 'DACH',
    EUROPE_NORDICS = 'EUROPE_NORDICS',
    APAC = 'APAC',
    AMERICAS = 'AMERICAS',
}
