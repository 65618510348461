/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarConfigurationOutputSchema } from '../models/CalendarConfigurationOutputSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Agent Calendar Configuration
     * @param agentId
     * @returns CalendarConfigurationOutputSchema Successful Response
     * @throws ApiError
     */
    public getAgentCalendarConfiguration(
        agentId: string,
    ): CancelablePromise<CalendarConfigurationOutputSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/calendars/{agent_id}/configuration',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
