/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityFeedFilters } from '../models/ActivityFeedFilters';
import type { ActivityFeedOutput } from '../models/ActivityFeedOutput';
import type { ActivityType } from '../models/ActivityType';
import type { PaginationResult_ActivityFeedOutput_ } from '../models/PaginationResult_ActivityFeedOutput_';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ActivityFeedService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Activity Types
     * @returns ActivityType Successful Response
     * @throws ApiError
     */
    public getActivityTypes(): CancelablePromise<Array<ActivityType>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/activity-feed/activity-types',
        });
    }
    /**
     * Get Activity Feed For Contact
     * @param contactId
     * @param organizationId
     * @param campaignId
     * @returns ActivityFeedOutput Successful Response
     * @throws ApiError
     */
    public getActivityFeedForContact(
        contactId: string,
        organizationId: string,
        campaignId?: (string | null),
    ): CancelablePromise<Array<ActivityFeedOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/activity-feed/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            query: {
                'organization_id': organizationId,
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Activities For Organization
     * @param organizationId
     * @param requestBody
     * @param page
     * @param size
     * @returns PaginationResult_ActivityFeedOutput_ Successful Response
     * @throws ApiError
     */
    public getAllActivitiesForOrganization(
        organizationId: string,
        requestBody: ActivityFeedFilters,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<PaginationResult_ActivityFeedOutput_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/activity-feed/',
            query: {
                'organization_id': organizationId,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
