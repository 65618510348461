/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBillingPortalSessionInput } from '../models/CreateBillingPortalSessionInput';
import type { CreateBillingPortalSessionOutput } from '../models/CreateBillingPortalSessionOutput';
import type { CreateCheckoutSessionInput } from '../models/CreateCheckoutSessionInput';
import type { CreateCheckoutSessionOutput } from '../models/CreateCheckoutSessionOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class StripeService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Stripe Webhook Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public stripeWebhookEndpoint(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/stripe/webhook',
        });
    }
    /**
     * Create Billing Portal Session
     * @param requestBody
     * @returns CreateBillingPortalSessionOutput Successful Response
     * @throws ApiError
     */
    public createBillingPortalSession(
        requestBody: CreateBillingPortalSessionInput,
    ): CancelablePromise<CreateBillingPortalSessionOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/stripe/billing-portal-session',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Checkout Session
     * @param requestBody
     * @returns CreateCheckoutSessionOutput Successful Response
     * @throws ApiError
     */
    public createCheckoutSession(
        requestBody: CreateCheckoutSessionInput,
    ): CancelablePromise<CreateCheckoutSessionOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/stripe/checkout-session',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
