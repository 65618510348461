/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkUpsertMessageTemplatesInput } from '../models/BulkUpsertMessageTemplatesInput';
import type { BulkUpsertMessageTemplatesOutput } from '../models/BulkUpsertMessageTemplatesOutput';
import type { CreateMessageTemplateInput } from '../models/CreateMessageTemplateInput';
import type { MessageTemplateOutput } from '../models/MessageTemplateOutput';
import type { UpdateMessageTemplateInput } from '../models/UpdateMessageTemplateInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MessageTemplatesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Message Templates From Organization
     * @param organizationId
     * @returns MessageTemplateOutput Successful Response
     * @throws ApiError
     */
    public getMessageTemplatesFromOrganization(
        organizationId: string,
    ): CancelablePromise<Array<MessageTemplateOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/message-templates/',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Message Template
     * @param requestBody
     * @returns MessageTemplateOutput Successful Response
     * @throws ApiError
     */
    public createMessageTemplate(
        requestBody: CreateMessageTemplateInput,
    ): CancelablePromise<MessageTemplateOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/message-templates/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Message Templates From Campaign
     * @param campaignId
     * @returns MessageTemplateOutput Successful Response
     * @throws ApiError
     */
    public getMessageTemplatesFromCampaign(
        campaignId: string,
    ): CancelablePromise<Array<MessageTemplateOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/message-templates/campaign/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Message Template
     * @param id
     * @param requestBody
     * @returns MessageTemplateOutput Successful Response
     * @throws ApiError
     */
    public updateMessageTemplate(
        id: string,
        requestBody: UpdateMessageTemplateInput,
    ): CancelablePromise<MessageTemplateOutput> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/message-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Message Template
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteMessageTemplate(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/message-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Upsert Message Templates For Campaign
     * @param requestBody
     * @returns BulkUpsertMessageTemplatesOutput Successful Response
     * @throws ApiError
     */
    public bulkUpsertMessageTemplatesForCampaign(
        requestBody: BulkUpsertMessageTemplatesInput,
    ): CancelablePromise<BulkUpsertMessageTemplatesOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/message-templates/bulk-upsert',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
