/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentCreateAuthState } from './AgentCreateAuthState';
import type { AgentUpdateAuthState } from './AgentUpdateAuthState';
import type { OrganizationCalendarAuthState } from './OrganizationCalendarAuthState';
export type AuthorizeRequest = {
    state: (AgentUpdateAuthState | AgentCreateAuthState | OrganizationCalendarAuthState);
    providerName: AuthorizeRequest.providerName;
};
export namespace AuthorizeRequest {
    export enum providerName {
        GOOGLE = 'google',
        MICROSOFT = 'microsoft',
        ZOOM = 'zoom',
    }
}

