/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlocklistOutput } from '../models/BlocklistOutput';
import type { CountBlocklistForReasonsInput } from '../models/CountBlocklistForReasonsInput';
import type { CreateBlocklistForCompaniesInput } from '../models/CreateBlocklistForCompaniesInput';
import type { CreateBlocklistForDomainsInput } from '../models/CreateBlocklistForDomainsInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BlocklistService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Blocklist For Domains
     * @param requestBody
     * @returns BlocklistOutput Successful Response
     * @throws ApiError
     */
    public createBlocklistForDomains(
        requestBody: CreateBlocklistForDomainsInput,
    ): CancelablePromise<Array<BlocklistOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/blocklist/domains',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Blocklist For Companies
     * @param requestBody
     * @returns BlocklistOutput Successful Response
     * @throws ApiError
     */
    public createBlocklistForCompanies(
        requestBody: CreateBlocklistForCompaniesInput,
    ): CancelablePromise<Array<BlocklistOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/blocklist/companies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Blocklist For Reasons
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public countBlocklistForReasons(
        requestBody: CountBlocklistForReasonsInput,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/blocklist/count',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
