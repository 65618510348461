/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HealthStatus } from '../models/HealthStatus';
import type { SystemProcessesReport } from '../models/SystemProcessesReport';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class HealthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Health Check
     * Checks the health of a project.
     *
     * It returns 200 if the project is healthy.
     * @returns HealthStatus Successful Response
     * @throws ApiError
     */
    public healthCheck(): CancelablePromise<HealthStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/health',
        });
    }
    /**
     * Error
     * @returns any Successful Response
     * @throws ApiError
     */
    public error(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/error',
        });
    }
    /**
     * Metrics
     * @returns SystemProcessesReport Successful Response
     * @throws ApiError
     */
    public metrics(): CancelablePromise<SystemProcessesReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/metrics',
        });
    }
}
