/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScraperFailureOutput } from '../models/ScraperFailureOutput';
import type { ScraperSuccessOutput } from '../models/ScraperSuccessOutput';
import type { ScrapeUrlInput } from '../models/ScrapeUrlInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ScraperService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Scrape Url
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public scrapeUrl(
        requestBody: ScrapeUrlInput,
    ): CancelablePromise<(ScraperSuccessOutput | ScraperFailureOutput)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/scraper/scrape-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
