/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportLeadInfoInput } from '../models/ImportLeadInfoInput';
import type { ImportLeadOutput } from '../models/ImportLeadOutput';
import type { ImportRedeemInput } from '../models/ImportRedeemInput';
import type { QualifiedImportInput } from '../models/QualifiedImportInput';
import type { QualifiedImportOutput } from '../models/QualifiedImportOutput';
import type { QueueQualifiedImportOutput } from '../models/QueueQualifiedImportOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LeadImportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Import From Lead Info
     * @param requestBody
     * @returns ImportLeadOutput Successful Response
     * @throws ApiError
     */
    public importFromLeadInfo(
        requestBody: ImportLeadInfoInput,
    ): CancelablePromise<ImportLeadOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-import/import-from-lead-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import From Redeem Ids
     * @param requestBody
     * @returns ImportLeadOutput Successful Response
     * @throws ApiError
     */
    public importFromRedeemIds(
        requestBody: ImportRedeemInput,
    ): CancelablePromise<ImportLeadOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-import/import-from-redeem-ids',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import From Qualified Search
     * @param requestBody
     * @returns QualifiedImportOutput Successful Response
     * @throws ApiError
     */
    public importFromQualifiedSearch(
        requestBody: QualifiedImportInput,
    ): CancelablePromise<QualifiedImportOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-import/qualified-import',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Queue Qualified Import
     * @param requestBody
     * @returns QueueQualifiedImportOutput Successful Response
     * @throws ApiError
     */
    public queueQualifiedImport(
        requestBody: QualifiedImportInput,
    ): CancelablePromise<QueueQualifiedImportOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-import/queue-qualified-import',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
