/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum StatusSequence {
    ACTIVE = 'active',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
    COMPLETED = 'completed',
    PAUSED = 'paused',
    WAITING_FOR_ACTION = 'waiting_for_action',
    STOPPED_EARLY = 'stopped_early',
}
