/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CampaignIdeaInput } from '../models/CampaignIdeaInput';
import type { CampaignIdeaSuggestion } from '../models/CampaignIdeaSuggestion';
import type { CampaignNameInput } from '../models/CampaignNameInput';
import type { CampaignNameOutput } from '../models/CampaignNameOutput';
import type { CompanySizeInput } from '../models/CompanySizeInput';
import type { CompanySizeSuggestion } from '../models/CompanySizeSuggestion';
import type { SuggestionInput } from '../models/SuggestionInput';
import type { SuggestionsOutput } from '../models/SuggestionsOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SuggestionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Suggestions
     * @param requestBody
     * @returns SuggestionsOutput Successful Response
     * @throws ApiError
     */
    public getSuggestions(
        requestBody: SuggestionInput,
    ): CancelablePromise<SuggestionsOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/suggestions/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Company Size Suggestions
     * @param requestBody
     * @returns CompanySizeSuggestion Successful Response
     * @throws ApiError
     */
    public getCompanySizeSuggestions(
        requestBody: CompanySizeInput,
    ): CancelablePromise<CompanySizeSuggestion> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/suggestions/company-sizes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Campaign Idea Suggestions
     * @param requestBody
     * @returns CampaignIdeaSuggestion Successful Response
     * @throws ApiError
     */
    public getCampaignIdeaSuggestions(
        requestBody: CampaignIdeaInput,
    ): CancelablePromise<CampaignIdeaSuggestion> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/suggestions/campaign-idea',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Campaign Name
     * @param requestBody
     * @returns CampaignNameOutput Successful Response
     * @throws ApiError
     */
    public getCampaignName(
        requestBody: CampaignNameInput,
    ): CancelablePromise<CampaignNameOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/suggestions/campaign-name',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
