/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailCategorizeInput } from '../models/EmailCategorizeInput';
import type { EmailCategorizeOutput } from '../models/EmailCategorizeOutput';
import type { EmailMessagesForContactOutput } from '../models/EmailMessagesForContactOutput';
import type { MessageFilters } from '../models/MessageFilters';
import type { MessageReplyCategory } from '../models/MessageReplyCategory';
import type { PaginationResult_EmailMessagesOutput_ } from '../models/PaginationResult_EmailMessagesOutput_';
import type { ReplyGroupCategory } from '../models/ReplyGroupCategory';
import type { ReplyToEmailInput } from '../models/ReplyToEmailInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EmailsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Opened Rate
     * @param organizationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOpenedRate(
        organizationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/emails/opened',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Replied Rate
     * @param organizationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getRepliedRate(
        organizationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/emails/replied',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Sent Emails
     * @param organizationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSentEmails(
        organizationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/emails/sent',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Outbound Emails
     * @param contactId
     * @param organizationId
     * @param campaignId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOutboundEmails(
        contactId: string,
        organizationId: string,
        campaignId?: (string | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/emails/outbound-emails/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            query: {
                'organization_id': organizationId,
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Categorize Email
     * @param requestBody
     * @returns EmailCategorizeOutput Successful Response
     * @throws ApiError
     */
    public categorizeEmail(
        requestBody: EmailCategorizeInput,
    ): CancelablePromise<EmailCategorizeOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/emails/categorize',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reply To Email
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public replyToEmail(
        requestBody: ReplyToEmailInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/emails/reply',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Messages
     * @param organizationId
     * @param requestBody
     * @param page
     * @param size
     * @returns PaginationResult_EmailMessagesOutput_ Successful Response
     * @throws ApiError
     */
    public getMessages(
        organizationId: string,
        requestBody: MessageFilters,
        page: number = 1,
        size: number = 10,
    ): CancelablePromise<PaginationResult_EmailMessagesOutput_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/emails/messages',
            query: {
                'organization_id': organizationId,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Messages For Contact
     * @param contactId
     * @param organizationId
     * @returns EmailMessagesForContactOutput Successful Response
     * @throws ApiError
     */
    public getAllMessagesForContact(
        contactId: string,
        organizationId: string,
    ): CancelablePromise<EmailMessagesForContactOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/emails/contacts/{contact_id}/messages',
            path: {
                'contact_id': contactId,
            },
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Mark Message Read
     * @param id
     * @param read
     * @returns any Successful Response
     * @throws ApiError
     */
    public markMessageRead(
        id: string,
        read: boolean,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/emails/mark-read/{id}',
            path: {
                'id': id,
            },
            query: {
                'read': read,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Reply Category
     * @param id
     * @param replyCategory
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateReplyCategory(
        id: string,
        replyCategory: MessageReplyCategory,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/emails/reply-category/{id}',
            path: {
                'id': id,
            },
            query: {
                'reply_category': replyCategory,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Reply Categories
     * @param grouped
     * @returns any Successful Response
     * @throws ApiError
     */
    public getReplyCategories(
        grouped: boolean = false,
    ): CancelablePromise<(Array<MessageReplyCategory> | Array<ReplyGroupCategory>)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/emails/reply-category',
            query: {
                'grouped': grouped,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
