/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSequenceInput } from '../models/CreateSequenceInput';
import type { StopSequenceInput } from '../models/StopSequenceInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SequencesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Sequence
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createSequence(
        requestBody: CreateSequenceInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sequences/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Sequence
     * @param contactId
     * @param campaignId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSequence(
        contactId: string,
        campaignId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences/',
            query: {
                'contact_id': contactId,
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Batch Create Sequences
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public batchCreateSequences(
        requestBody: Array<CreateSequenceInput>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sequences/batch-create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Stop Sequence
     * @param sequenceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public stopSequence(
        sequenceId: string,
        requestBody: StopSequenceInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sequences/{sequence_id}/stop',
            path: {
                'sequence_id': sequenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Sequence Status
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSequenceStatus(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences/status',
        });
    }
}
