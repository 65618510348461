/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MemberListOutput } from '../models/MemberListOutput';
import type { UpdateMembershipInput } from '../models/UpdateMembershipInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MembershipsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List Members
     * @param organizationId
     * @param page
     * @param size
     * @returns MemberListOutput Successful Response
     * @throws ApiError
     */
    public listMembers(
        organizationId: string,
        page: number = 1,
        size: number = 10,
    ): CancelablePromise<MemberListOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/memberships/in-organization/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Membership
     * @param membershipId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateMembership(
        membershipId: string,
        requestBody: UpdateMembershipInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/memberships/{membership_id}',
            path: {
                'membership_id': membershipId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Membership
     * @param membershipId
     * @returns void
     * @throws ApiError
     */
    public deleteMembership(
        membershipId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/memberships/{membership_id}',
            path: {
                'membership_id': membershipId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
