/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptInviteInput } from '../models/AcceptInviteInput';
import type { AcceptInviteOutput } from '../models/AcceptInviteOutput';
import type { InviteListOutput } from '../models/InviteListOutput';
import type { InviteMemberInput } from '../models/InviteMemberInput';
import type { InviteOutput } from '../models/InviteOutput';
import type { UpdateInviteInput } from '../models/UpdateInviteInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InvitesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Accept Invite
     * @param requestBody
     * @returns AcceptInviteOutput Successful Response
     * @throws ApiError
     */
    public acceptInvite(
        requestBody: AcceptInviteInput,
    ): CancelablePromise<AcceptInviteOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/invites/accept',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Invite By Code
     * @param inviteCode
     * @returns InviteOutput Successful Response
     * @throws ApiError
     */
    public getInviteByCode(
        inviteCode: string,
    ): CancelablePromise<InviteOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invites/{invite_code}',
            path: {
                'invite_code': inviteCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Invited Members
     * @param organizationId
     * @param page
     * @param size
     * @returns InviteListOutput Successful Response
     * @throws ApiError
     */
    public listInvitedMembers(
        organizationId: string,
        page: number = 1,
        size: number = 10,
    ): CancelablePromise<InviteListOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invites/in-organization/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Invite Member
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public inviteMember(
        requestBody: InviteMemberInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/invites/invite-member',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Invite
     * @param inviteId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteInvite(
        inviteId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/invites/{invite_id}',
            path: {
                'invite_id': inviteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Invite
     * @param inviteId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateInvite(
        inviteId: string,
        requestBody: UpdateInviteInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/invites/{invite_id}',
            path: {
                'invite_id': inviteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
