/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailValidationResponse } from '../models/EmailValidationResponse';
import type { ValidateEmailInput } from '../models/ValidateEmailInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EmailValidatorService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Validate Email
     * @param requestBody
     * @returns EmailValidationResponse Successful Response
     * @throws ApiError
     */
    public validateEmail(
        requestBody: ValidateEmailInput,
    ): CancelablePromise<EmailValidationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/email-validator/validate-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
