/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyExclusionLevel } from '../models/CompanyExclusionLevel';
import type { CRMIntegrationOutput } from '../models/CRMIntegrationOutput';
import type { CRMType } from '../models/CRMType';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CrmService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Crm In Organization
     * @param organizationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCrmInOrganization(
        organizationId: string,
    ): CancelablePromise<(CRMIntegrationOutput | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/crm/',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Settings
     * @param crmId
     * @param companyExclusion
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateSettings(
        crmId: string,
        companyExclusion: CompanyExclusionLevel,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/crm/{crm_id}/settings',
            path: {
                'crm_id': crmId,
            },
            query: {
                'company_exclusion': companyExclusion,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Crm Integration
     * @param crmId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteCrmIntegration(
        crmId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/crm/{crm_id}',
            path: {
                'crm_id': crmId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Sync Crm
     * @param crmType
     * @returns any Successful Response
     * @throws ApiError
     */
    public syncCrm(
        crmType: CRMType,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/crm/{crm_type}/webhook',
            path: {
                'crm_type': crmType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
