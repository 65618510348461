/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ConstantsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Industries
     * @returns any Successful Response
     * @throws ApiError
     */
    public getIndustries(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/constants/industries',
        });
    }
    /**
     * Get Company Sizes
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCompanySizes(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/constants/company-sizes',
        });
    }
    /**
     * Get Tone Of Voice
     * @returns any Successful Response
     * @throws ApiError
     */
    public getToneOfVoice(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/constants/tone-of-voice',
        });
    }
    /**
     * Get Languages
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLanguages(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/constants/languages',
        });
    }
    /**
     * Get Countries
     * @returns string Successful Response
     * @throws ApiError
     */
    public getCountries(): CancelablePromise<Array<Record<string, string>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/constants/countries',
        });
    }
    /**
     * Get Timezones
     * @returns any Successful Response
     * @throws ApiError
     */
    public getTimezones(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/constants/timezones',
        });
    }
}
