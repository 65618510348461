/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BlockReason {
    BAD_TIMING = 'bad_timing',
    BOUNCED = 'bounced',
    CONTACT_REFUSED = 'contact_refused',
    INTERESTED = 'interested',
    MEETING = 'meeting',
    OTHER = 'other',
    OUT_OF_OFFICE = 'out_of_office',
    UNKNOWN = 'unknown',
    UNSUBSCRIBED = 'unsubscribed',
    WRONG_PERSON = 'wrong_person',
    EXCLUDE_FROM_SEARCH = 'exclude_from_search',
    EXCLUDE_FROM_CRM = 'exclude_from_crm',
}
