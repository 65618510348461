/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadSearchInput } from '../models/LeadSearchInput';
import type { LeadSearchOutput } from '../models/LeadSearchOutput';
import type { LeadSearchUpdateInput } from '../models/LeadSearchUpdateInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LeadSearchService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Lead Search
     * @param leadSearchId
     * @returns LeadSearchOutput Successful Response
     * @throws ApiError
     */
    public getLeadSearch(
        leadSearchId: string,
    ): CancelablePromise<LeadSearchOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/lead-search/{lead_search_id}',
            path: {
                'lead_search_id': leadSearchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Lead Search
     * @param leadSearchId
     * @param requestBody
     * @returns LeadSearchOutput Successful Response
     * @throws ApiError
     */
    public updateLeadSearch(
        leadSearchId: string,
        requestBody: LeadSearchUpdateInput,
    ): CancelablePromise<LeadSearchOutput> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/lead-search/{lead_search_id}',
            path: {
                'lead_search_id': leadSearchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Lead Search
     * @param leadSearchId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteLeadSearch(
        leadSearchId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/lead-search/{lead_search_id}',
            path: {
                'lead_search_id': leadSearchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Lead Search
     * @param requestBody
     * @returns LeadSearchOutput Successful Response
     * @throws ApiError
     */
    public createLeadSearch(
        requestBody: LeadSearchInput,
    ): CancelablePromise<LeadSearchOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-search/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Lead Searches
     * @param organizationId
     * @param campaignId
     * @returns LeadSearchOutput Successful Response
     * @throws ApiError
     */
    public getLeadSearches(
        organizationId?: (string | null),
        campaignId?: (string | null),
    ): CancelablePromise<Array<LeadSearchOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/lead-search/',
            query: {
                'organization_id': organizationId,
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
