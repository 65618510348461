/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CampaignType {
    INDUSTRY_BASED = 'industry_based',
    PERSONA_BASED = 'persona_based',
    HIRING_INTENT = 'hiring_intent',
    TECHNOLOGY = 'technology',
    LOOKALIKE = 'lookalike',
    COMPETITORS_FOLLOWERS = 'competitors_followers',
    ACCOUNT_LIST = 'account_list',
    FOLLOW_UP = 'follow_up',
    MORE_COMPLEX = 'more_complex',
    LEGACY = 'legacy',
}
