/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CognismSearchOutput } from '../models/CognismSearchOutput';
import type { EvaluatedSearchOutput } from '../models/EvaluatedSearchOutput';
import type { GenerateFiltersInput } from '../models/GenerateFiltersInput';
import type { PositiveAndNegativeLeadsInput } from '../models/PositiveAndNegativeLeadsInput';
import type { PositiveAndNegativeLeadsOutput } from '../models/PositiveAndNegativeLeadsOutput';
import type { QualifiedSearchLeadsInput } from '../models/QualifiedSearchLeadsInput';
import type { SearchLeadsInput } from '../models/SearchLeadsInput';
import type { SearchOutput } from '../models/SearchOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LeadFinderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Generate Search Filters
     * @param requestBody
     * @returns CognismSearchOutput Successful Response
     * @throws ApiError
     */
    public generateSearchFilters(
        requestBody: GenerateFiltersInput,
    ): CancelablePromise<CognismSearchOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-finder/generate-filters',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Qualified Leads
     * @param requestBody
     * @returns EvaluatedSearchOutput Successful Response
     * @throws ApiError
     */
    public searchQualifiedLeads(
        requestBody: QualifiedSearchLeadsInput,
    ): CancelablePromise<EvaluatedSearchOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-finder/qualified-search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search
     * @param requestBody
     * @returns SearchOutput Successful Response
     * @throws ApiError
     */
    public search(
        requestBody: SearchLeadsInput,
    ): CancelablePromise<SearchOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-finder/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Positive And Negative Leads
     * @param requestBody
     * @returns PositiveAndNegativeLeadsOutput Successful Response
     * @throws ApiError
     */
    public getPositiveAndNegativeLeads(
        requestBody: PositiveAndNegativeLeadsInput,
    ): CancelablePromise<PositiveAndNegativeLeadsOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-finder/positive-and-negative-leads',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Estimated Lead Search Size
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEstimatedLeadSearchSize(
        requestBody: SearchLeadsInput,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/lead-finder/estimated-lead-search-size',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
