/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CampaignOutput } from '../models/CampaignOutput';
import type { CampaignPerOrganizationOutput } from '../models/CampaignPerOrganizationOutput';
import type { CreateCampaignInput } from '../models/CreateCampaignInput';
import type { SummarizeCampaignInput } from '../models/SummarizeCampaignInput';
import type { UpdateCampaignInput } from '../models/UpdateCampaignInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CampaignsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Campaigns With Contacted Leads
     * @param organizationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCampaignsWithContactedLeads(
        organizationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/campaigns/contacted-leads',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Campaign
     * @param campaignId
     * @returns CampaignOutput Successful Response
     * @throws ApiError
     */
    public getCampaign(
        campaignId: string,
    ): CancelablePromise<CampaignOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Campaign
     * @param campaignId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateCampaign(
        campaignId: string,
        requestBody: UpdateCampaignInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Campaign
     * @param campaignId
     * @returns string Successful Response
     * @throws ApiError
     */
    public deleteCampaign(
        campaignId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Campaigns
     * @param organizationId
     * @param campaignStats
     * @returns CampaignPerOrganizationOutput Successful Response
     * @throws ApiError
     */
    public getCampaigns(
        organizationId: string,
        campaignStats: boolean = false,
    ): CancelablePromise<Array<CampaignPerOrganizationOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/campaigns/',
            query: {
                'organization_id': organizationId,
                'campaign_stats': campaignStats,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Campaign
     * @param organizationId
     * @param requestBody
     * @returns CampaignOutput Successful Response
     * @throws ApiError
     */
    public createCampaign(
        organizationId: string,
        requestBody: CreateCampaignInput,
    ): CancelablePromise<CampaignOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/campaigns/',
            query: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Summarize Campaign Target
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public summarizeCampaignTarget(
        requestBody: SummarizeCampaignInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/campaigns/summarize-target',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Campaign Email Tracking
     * @param campaignId
     * @param emailTracking
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateCampaignEmailTracking(
        campaignId: string,
        emailTracking: boolean,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/campaigns/{campaign_id}/email-tracking',
            path: {
                'campaign_id': campaignId,
            },
            query: {
                'email_tracking': emailTracking,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
