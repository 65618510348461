/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyOutput } from '../models/CompanyOutput';
import type { CreateCompanyInput } from '../models/CreateCompanyInput';
import type { FindCompanyInput } from '../models/FindCompanyInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CompaniesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Company
     * @param requestBody
     * @returns CompanyOutput Successful Response
     * @throws ApiError
     */
    public getCompany(
        requestBody: FindCompanyInput,
    ): CancelablePromise<CompanyOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/companies/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upsert Company
     * @param requestBody
     * @returns CompanyOutput Successful Response
     * @throws ApiError
     */
    public upsertCompany(
        requestBody: CreateCompanyInput,
    ): CancelablePromise<CompanyOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/companies/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Batch Upsert Companies
     * @param requestBody
     * @returns CompanyOutput Successful Response
     * @throws ApiError
     */
    public batchUpsertCompanies(
        requestBody: Array<CreateCompanyInput>,
    ): CancelablePromise<Array<CompanyOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/companies/batch-upsert',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
