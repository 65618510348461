/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchCreateFeedbackInput } from '../models/BatchCreateFeedbackInput';
import type { DeleteFeedbackInput } from '../models/DeleteFeedbackInput';
import type { DeleteFeedbackOutput } from '../models/DeleteFeedbackOutput';
import type { FeedbackResponse } from '../models/FeedbackResponse';
import type { FeedbackResponseWithRequest } from '../models/FeedbackResponseWithRequest';
import type { FeedbackUpdate } from '../models/FeedbackUpdate';
import type { GetFeedbackInput } from '../models/GetFeedbackInput';
import type { SubmitFeedbackInput } from '../models/SubmitFeedbackInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FeedbackService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Feedbacks
     * @param requestBody
     * @returns FeedbackResponseWithRequest Successful Response
     * @throws ApiError
     */
    public getFeedbacks(
        requestBody: GetFeedbackInput,
    ): CancelablePromise<Array<FeedbackResponseWithRequest>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/feedback/get-feedbacks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Submit Feedback
     * @param requestBody
     * @returns FeedbackResponseWithRequest Successful Response
     * @throws ApiError
     */
    public submitFeedback(
        requestBody: SubmitFeedbackInput,
    ): CancelablePromise<FeedbackResponseWithRequest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/feedback/submit-feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Feedback
     * @param requestBody
     * @returns DeleteFeedbackOutput Successful Response
     * @throws ApiError
     */
    public deleteFeedback(
        requestBody: DeleteFeedbackInput,
    ): CancelablePromise<DeleteFeedbackOutput> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/feedback/delete-feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Feedback
     * @param id
     * @param requestBody
     * @returns FeedbackResponse Successful Response
     * @throws ApiError
     */
    public updateFeedback(
        id: string,
        requestBody: FeedbackUpdate,
    ): CancelablePromise<FeedbackResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/feedback/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Batch Create
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public batchCreate(
        requestBody: BatchCreateFeedbackInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/feedback/batch-create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
