/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSubscriptionOutput } from '../models/ActiveSubscriptionOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SubscriptionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Active Subscription
     * @param organizationId
     * @returns ActiveSubscriptionOutput Successful Response
     * @throws ApiError
     */
    public getActiveSubscription(
        organizationId: string,
    ): CancelablePromise<ActiveSubscriptionOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/subscriptions/active',
            query: {
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
