/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserInput } from '../models/CreateUserInput';
import type { Page_UserOutput_ } from '../models/Page_UserOutput_';
import type { UpdateUserInput } from '../models/UpdateUserInput';
import type { UserOrganizationOutput } from '../models/UserOrganizationOutput';
import type { UserOutput } from '../models/UserOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * All Users
     * @param search
     * @param page Page number
     * @param size Page size
     * @returns Page_UserOutput_ Successful Response
     * @throws ApiError
     */
    public allUsers(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_UserOutput_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create User
     * @param requestBody
     * @returns UserOutput Successful Response
     * @throws ApiError
     */
    public createUser(
        requestBody: CreateUserInput,
    ): CancelablePromise<UserOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Find User
     * @param id
     * @returns UserOutput Successful Response
     * @throws ApiError
     */
    public findUser(
        id: string,
    ): CancelablePromise<UserOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Find User By Auth Id
     * @param authUserId
     * @returns UserOutput Successful Response
     * @throws ApiError
     */
    public findUserByAuthId(
        authUserId: string,
    ): CancelablePromise<UserOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/auth/{auth_user_id}',
            path: {
                'auth_user_id': authUserId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateUser(
        id: string,
        requestBody: UpdateUserInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Organizations
     * @param id
     * @returns UserOrganizationOutput Successful Response
     * @throws ApiError
     */
    public getUserOrganizations(
        id: string,
    ): CancelablePromise<Array<UserOrganizationOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{id}/organizations',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
