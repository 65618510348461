/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactedLeadsFilters } from '../models/ContactedLeadsFilters';
import type { ContactOutput } from '../models/ContactOutput';
import type { CreateContactInput } from '../models/CreateContactInput';
import type { PaginationResult_ContactedLeadsOutput_ } from '../models/PaginationResult_ContactedLeadsOutput_';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ContactsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Contacted Leads
     * @param organizationId
     * @param page
     * @param size
     * @param requestBody
     * @returns PaginationResult_ContactedLeadsOutput_ Successful Response
     * @throws ApiError
     */
    public getContactedLeads(
        organizationId: string,
        page: number = 1,
        size: number = 10,
        requestBody?: (ContactedLeadsFilters | null),
    ): CancelablePromise<PaginationResult_ContactedLeadsOutput_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contacts/contacted-leads',
            query: {
                'organization_id': organizationId,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Contact
     * @param contactId
     * @returns ContactOutput Successful Response
     * @throws ApiError
     */
    public getContact(
        contactId: string,
    ): CancelablePromise<ContactOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contacts/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upsert Contact
     * @param requestBody
     * @returns ContactOutput Successful Response
     * @throws ApiError
     */
    public upsertContact(
        requestBody: CreateContactInput,
    ): CancelablePromise<ContactOutput> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contacts/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Batch Upsert Contacts
     * @param requestBody
     * @returns ContactOutput Successful Response
     * @throws ApiError
     */
    public batchUpsertContacts(
        requestBody: Array<CreateContactInput>,
    ): CancelablePromise<Array<ContactOutput>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contacts/batch-upsert',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
